<template>
  <div>
    <v-alert
      v-model="alert"
      dismissible
      color="#FF6D6D"
      border="left"
      elevation="2"
      colored-border
      icon="warning"
      >{{ message }}</v-alert
    >
    <v-toolbar flat>
      <v-toolbar-title>Settings</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn color="primary" dark @click="initialize" class="mb-2">Fetch</v-btn>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn color="primary" dark @click="save(false)" class="mb-2">Save</v-btn>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn color="primary" dark @click="save(true)" class="mb-2"
        >Save (Default Value)</v-btn
      >
      <v-divider class="mx-2" inset vertical></v-divider>

      <v-btn color="red" dark @click="pubsubSlotart()" class="mb-2 ml-5"
        >PubSub Slotart</v-btn
      >
    </v-toolbar>

    <!-- <json-viewer
      name="df"
      :value="this.$store.state.settings.setting"
      :expand-depth="5"
      theme="jv-dark"
      sort
    ></json-viewer>-->
    <v-jsoneditor
      v-model="settings"
      :options="options"
      :plus="false"
      height="2800px"
      @error="onError"
    />
  </div>
</template>
<script>
import VJsoneditor from "v-jsoneditor";

export default {
  name: "app",
  components: {
    VJsoneditor,
  },
  data() {
    return {
      settings: {},
      alert: false,
      message: "",
      options: {
        theme: "jsoneditor",
        onValidationError: function(errors) {
          // eslint-disable-next-line no-console
          console.log(errors);
          errors.forEach((error) => {
            switch (error.type) {
              case "validation": // schema validation error
                break;
              case "customValidation": // custom validation error
                break;
              case "error": // json parse error
                break;
            }
          });
        },
      },
    };
  },
  async mounted() {
    await this.initialize();
  },

  methods: {
    onError() {
      // eslint-disable-next-line no-console
      console.log("error");
    },
    async initialize() {
      // await this.$store.dispatch("settings/getSettings");
      // this.setting = this.$store.state.settings.setting;
    },
    async pubsubSlotart() {
      await this.$store.dispatch("settings/pubsubSlotart");
    },
    async save(isDefaultValue) {
      await this.$store.dispatch("settings/updateGeneralSettings", {
        ...this.settings,
        isDefaultValue,
      });
      await this.$store.dispatch("servers/getAll");
      var that = this;

      this.$store.state.servers.all
        .filter((m) => m.url != null)
        .forEach(function(doc) {
          that.$store.dispatch("settings/postSettings", {
            url: doc.data().url,
            settings: that.settings,
          });
        });
      that.message = "Success";
      that.alert = true;

      // await this.$store.dispatch("settings/getSettings");
      // this.setting = this.$store.state.settings.setting;
    },
  },
  async created() {
    await this.$store.dispatch("settings/getGeneralSettings");
    this.settings = this.$store.state.settings.generalSetting;
    // this.settings = (
    //   await db
    //     .collection("settings")
    //     .doc("settings")
    //     .get()
    // ).data();
  },
};
</script>
<style>
.jsoneditor-field {
  color: #b7b3c3 !important;
}
a.jsoneditor-value.jsoneditor-url,
div.jsoneditor-value.jsoneditor-url {
  color: #00cfff !important;
}
div.jsoneditor-value.jsoneditor-array,
div.jsoneditor-value.jsoneditor-object {
  color: #b7abab !important;
}
div.jsoneditor-readonly {
  color: #c7c1c1;
}
div.jsoneditor-value.jsoneditor-string {
  color: #00cfff;
}
</style>
